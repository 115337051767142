

html, body {
  ::-webkit-scrollbar{
    width: 6px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(68, 68, 68, 0.16);
    background-position:center;
    background-repeat:no-repeat;
    -webkit-border-radius: 3.5px;
    border-radius: 3.5px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  ::-webkit-scrollbar-track {
    background: none;
  }
  ::-webkit-scrollbar-button {
    background-image: none;
    background-repeat:no-repeat;
    width: 0;
    height: 0;
  }
}

body {
  overflow-x: hidden;
  font-size: $fontSizeMobile;
  font-family: $fontFamily;
  color: $gray600;
  background-color: #FFFFFF;
  &.subMenuOpened {
    .mainHeader {
      left: 320px;
      width: calc(100% - 320px);
    }
    .mainLayoutContent {
      padding-left: 320px;
    }
  }
}

@media (min-width: $screen-lg) {
  body {
    font-size: $fontSize;
  }
}