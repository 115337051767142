.leadPageWrapper {
  .ldTitleHolder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    .btnHolder {
      display: flex;
      align-items: center;
      & > * + * {
        margin-left: 10px;
      }
    }
  }
  .timeLineHolder {
    padding: 30px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid $borderColor;
  }
  .timeLine {
    margin-top: 30px;
    .text-semi-bold {
      color: #313462;
    }
  }
  h2 {
    color: $primaryColor;
    font-weight: 500;
  }
}

.leadDetailsHolder {

}
.leadDetails {
  padding: 30px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid $borderColor;
}
.TitleActiveWrapper {
  display: flex;
  gap: 10px;
}

.leadDetailsHeading {
  position: relative;
  .moreIcon {
    position: absolute;
    top: -8px;
    right: -20px;
  }
}

.timeLineHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leadDetailsTableLike {
  &.campSettingsTable {
    .tr {
      .td {
        &:first-child {
          white-space: nowrap;
        }
      }
    }
  }

  .tableLike {
    display: table;
    width: 100%;
    font-size: $fontSizeMute;
  }
  .tr {
    display: table-row;
    .td {
      &:first-child {
        color: #313462;
        font-weight: 600;
        padding-left: 0;
      }
      &:last-child {
        font-weight: 300;
        padding-right: 0;
        width: 100%;
      }
    }
    &:last-child {
      .td {
        padding-bottom: 0;
      }
    }
  }
  .td {
    display: table-cell;
    padding: 5px;
  }
  .tr + .tr {
    .td {
      border-top: 1px solid $borderColor;
    }
  }
  .MuiChip-root {
    height: 27px;
    &.slChip {
      margin-right: 5px;
      margin-bottom: 5px;
      background: rgba(46, 73, 209, 0.106753);
      border: 1px solid transparent;
      color: $primaryColor;
      border-radius: 5px;
      &:hover {
        background:rgba(46, 73, 209, 0.15);
      }
    }
  }
}
.mb0{
  margin-bottom: 0px !important;
}
.hintWrapper{
  padding: 10px;
  background: #FAFBFD;
  border: 1px solid #F2F5F9;
  border-radius: 10px;
  margin-top: -10px;
  margin-bottom: 15px;
  .hintTitle{
    display: inline-block;
    font-weight: 500;
    font-size: 10px;
    margin-bottom: 5px;
    color: rgb(175, 177, 179);
  }
  .hintList{
    padding-left: 20px;
    >li{
      font-size: 10px;
      color: rgb(175, 177, 179);
    }
  }
}
.showMore{
  display: inline-flex;
  align-items: center;
  gap: 5px;
  min-width: max-content;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin: 0 5px;
  &:hover{
    opacity: 0.7;
    transition: all 0.4s;
  }
  .showMoreArrow{
    height: 8px;
    width: 8px;
  }
}
.messageField{
  textarea::-webkit-scrollbar{
    display: none !important;
  }
}
.disabledsaveSMSButton{
  path{
    fill: grey;
  }
}
.MuiChip-root {

  &.tagLikeChip {
    margin-right: 5px;
    margin-bottom: 5px;
    background: #EEF5FC;
    border: 1px solid #DBE8F7;
    color: #0576ED;
    border-radius: 4px;
    &:hover {
      background: darken(#EEF5FC, 2%);
    }
    &.success, &.active {
      background-color: $successColor;
      border-color: $successColor;
      color: $textSuccessColor;
      &:hover {
        background: darken($successColor, 2%);
      }
    }
    &.warning, &.failed {
      background-color: $failedColor;
      border-color: $failedColor;
      color: $textFailedColor;
      &:hover {
        background: darken($failedColor, 2%);
      }
    }
    &.blackList {
      background-color: $blacklistColor;
      border-color: $blacklistColor;
      color: $textBlacklistColor;
      &:hover {
        background: darken($blacklistColor, 2%);
      }
    }
    &.inactive {
      background-color: $inactiveColor;
      border-color: $inactiveColor;
      color: $textInactiveColor;
      &:hover {
        background: darken($inactiveColor, 2%);
      }
    }
    &.pending, &.draft{
      background-color: $pendingColor;
      border-color: $pendingColor;
      color: $textPendingColor;
      &:hover {
        background: darken($pendingColor, 2%);
      }
    }
    &.flagged {
      background-color: $flaggedColor;
      border-color: $flaggedColor;
      color: #fff;
      &:hover {
        background: darken($flaggedColor, 2%);
      }
    }
    &.scheduled{
      background-color: $sheduledColor;
      border-color: $sheduledColor;
      color: $textSheduledColor;
      &:hover {
        background: darken($sheduledColor, 2%);
      }
    }
    &.paused {
      background-color: $pausedColor;
      border-color: $pausedColor;
      color: $textPausedColor;
      &:hover {
        background: darken($pausedColor, 2%);
      }
    }
    &.completed {
      background-color: $completedColor;
      border-color: $completedColor;
      color: $textCompletedColor;
      &:hover {
        background: darken($completedColor, 2%);
      }
    }
  }
}

@media (min-width: $screen-xxl) {
  .leadPageWrapper {
    display: flex;
    //padding: 0 15px;
    //flex-wrap: wrap;
    .leadDetailsHolder {
      //width: calc(33.333% - 7.5px);
      width: calc(50% - 7.5px);
      //max-width: 600px;
      //min-width: 530px;
    }
    .leadDetails {
      padding: 30px;
      border-radius: 10px;
      border: 1px solid $borderColor;
    }
    .timeLineHolder {
      //width: calc(66.666% - 7.5px);
      width: calc(50% - 7.5px);
      //max-width: 600px;
      margin-left: 15px;
      //min-width: 680px;

    }
    .timeLine {
      margin-top: 0;
      height: 100%;
    }
  }
}
