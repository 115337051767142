.shortUrlButton{
  background-color: transparent;
  border: none;
  border-radius: 5px !important;
}
.shortUrlButtonIcon:hover{
  opacity: 0.7;
  transition: all 0.4s;
}
.shortUrlButtonIcon{
  cursor: pointer;
  opacity: 1;
  transition: all 0.4s;
}