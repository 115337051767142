.tableHolder {
  border-radius: 8px;
  overflow: hidden;
  border: 0.5px solid #E5E5E5;
  .tableScrollHolder {
    position: relative;
    overflow-y: auto;
  }

  input[type="checkbox"] {
    width: 15px;
    height: 15px;
    opacity: .5;
    &:checked {
      opacity: 1;
    }
  }
  table {
    td {
      background: white;
    }
    .col-sticky {
      position: sticky;
      right: 0;
      text-align: center;
    }
  }
  &.rowClickable {
    td {
      cursor: pointer;
    }
    tr {
      &:hover {
        td {
          background-color: #ebeffd;
        }
      }
      &.emptyTableRow {
        &:hover {
          td {
            background-color: transparent;
          }
        }
      }
    }
  }
  &.stlg {
    th {
      padding-top: 30px;
    }
  }
  &.noBorderTop {
    th {
      border-top: none;
    }
  }
  .checkboxHolder {
    //display: none;
    //position: absolute;
    //left: -13px;
    //width: 10px;
    //height: 10px;
    //background-color: #ff0000;
    //margin-top: 5px;
    padding: 0;
  }
  th.checkboxHolder {
    padding-top: 5px;
  }
  tr {
    position: relative;
    line-height: 23px;
    //th {
    //  &:first-child {
    //    border-radius: 12px 0 0 12px;
    //  }
    //  &:last-child {
    //    border-radius: 0 12px 12px 0;
    //  }
    //}
  }
  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
  }
  th {
    padding: 5px 20px 5px 20px;
    white-space: nowrap;
    background-color: #F1F3F4;
    //color: #313462;
    font-size: 12px;
    font-weight: 500;
    font-family: $fontFamilySecondary;
    &.hasArrow {
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      &:after {
        content: "";
        display: inline-block;
        background: url("#{$imgPath}/thin_long_02_down.svg") no-repeat;
        background-size: contain;
        background-position: center;
        height: 11px;
        width: 8px;
        margin-top: -3px;
        vertical-align: middle;
        margin-left: 3px;
      }

      &.up {
        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }
  th, td {
    text-align: left;
    vertical-align: middle;
  }
  td {
    font-weight: 300;
    padding: 5px 20px 5px 20px;
    &.col-sticky {
    }
  }
  //tr {
  //  th, td {
  //    &:first-child {
  //      padding-left: 0;
  //    }
  //    &:last-child {
  //      padding-right: 0;
  //    }
  //  }
  //}
  &.hasCheckboxes {
    tr {
      th, td {
        &:nth-child(2) {
          padding-left: 0;
        }
        //&:last-child {
        //  padding-right: 0;
        //}
      }
    }
  }
}
.tableId{
  width: 22px;
}

.MuiTablePagination-root {
  .MuiTablePagination-select {
    font-size: 14px;
    color: grey;
    font-weight: 400;
  }
  .MuiListItem-button{
    color: red !important;
  }
  .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
    top: calc(50% - 10px);
    color: grey;
  }
  .MuiTypography-root {
    font-size: 14px;
    color: grey;
  }
}

.emptyTableHolder {
  .imgHolder {
    display: flex;
    justify-content: center;
    margin: 30px 0 15px;
  }
  p {
    font-weight: 400;
    font-size: 14px;
  }
}

.formatTableHolder {
  .formatTable {
    width: 100%;
    tbody {
      display: block;
      height: 230px;
      overflow: auto;
    }
    th, td {
      padding: 8px 0;
    }
    th {
      font-weight: 600;
      font-size: 14px;
      padding-top: 0;
      text-align: left;
      &:first-child {
        width: 65%;
      }
      &:last-child {
        width: 35%;
      }
    }
    td {

    }
    tr {
      display: flex;
      justify-content: space-between;
      td {
        &:first-child {
          // width: calc(100% - 150px);
          width: 65%;
          font-weight: 300;
          font-size: 14px;
        }
        &:last-child {
          width: 35%;
        }
      }
      &:last-child {
        td {
          padding-bottom: 0;
        }
      }
    }
    .MuiFormControl-root {
      max-width: 150px;
    }
  }
}

.tableProgressBarItem {
  display: flex;
  align-items: center;
  .qnt {
    flex-shrink: 0;
    font-size: 10px;
    line-height: 12px;
    margin-left: 5px;
    width: 30px;
    text-align: right;
  }
  .pbItemHolder {
    width: 100%;
    position: relative;
    height: 5px;
    border-radius: 2px;
    background: $gray300;
    .pbItem {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      background: $successColor;
      border-radius: 2px;
    }
  }
  &.secondary {
    .pbItemHolder {
      .pbItem {
        background: $primaryColor;
      }
    }
  }

}

.timeLineTable {
  table {
    width: 100%;
    border-collapse: collapse;
  }
  td {
    padding: 5px;
    font-size: 14px;
    font-weight: 300;
    &.hasBorder {
      border-bottom: 1px solid $borderColor;
      td {
        border-top: 1px solid $borderColor;
      }
    }
  }
  tr {
    td {
      &:first-child {
        width: 170px;
      }
    }
  }
  .MuiCollapse-container {
    background-color: $gray100;
  }
}
