.statisticsPageHolder {
  display: flex;
  margin: -7.5px;
  width: 100%;
  .chartHolder {
    padding: 7.5px;
    flex-shrink: 0;
    width: 320px;
  }
  .infoHolder {
    padding: 7.5px;
    flex-shrink: 0;
    width: calc(100% - 320px);
  }
  .spBlock {
    background: #FFFFFF;
    box-shadow: 0 2px 10px rgba(236, 226, 226, 0.5);
    border-radius: 10px;
    width: 100%;
    overflow: hidden;
    padding: 15px;
  }
  .spBlock + .spBlock {
    margin-top: 15px;
  }
  .segmentsFilter {
    margin-top: 15px;
  }
  .highcharts-background {
    fill: #fff!important;
  }
  .pageHeadingBlockWrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .part + .part {
      border-left: 1px solid $borderColor;
      padding-left: 45px;
      margin-left: 45px;
    }
    .part {
      //&:last-child {
      //  width: 100%;
      //  border-left: none;
      //  padding-left: 0;
      //  margin-left: 0;
      //  margin-top: 30px;
      //}
    }
  }
}