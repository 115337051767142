.btnHolder {
  &.hasPadTop {
    padding-top: 30px;
  }
  &.hasPadBottom {
    padding-bottom: 30px;
  }
  &.twoBtn {
    .part {
    }
    .cancelModal{
      border-radius: 4px;
    }
    .continueModal{
      border-radius: 4px;
    }
    .part + .part {
      margin-top: 15px;
    }
  }
}

.primaryColor {
  background: $primaryColor !important;
  &:hover {
    background: #046ad4 !important;
  }
}

.primaryColorOutlined {
  color: $primaryColor !important;
  border-color: $primaryColor !important;
  &:hover {
    color: #046ad4 !important;
    border-color: #046ad4 !important;
  }
}

.btn {
  display: inline-flex;
  border: 2px solid $primaryColor;
  border-radius: 4px;
  text-align: center;
  color: $primaryColor;
  line-height: 22px;
  padding: 8px 40px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  text-decoration: none;
  &:hover {
    background: $primaryColor;
    color: #fff;
  }
  &.contained {
    background: $primaryColor;
    color: #fff;
    &:hover {
      background: darken($primaryColor, 5%);
    }
  }
}

.dropDownLink {
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  padding: 0;
  outline: none;
  box-shadow: none;
  font-size: $fontSizeMute;
  cursor: pointer;
}

@media (min-width: $screen-sm) {
  .btnHolder {
    &.twoBtn {
      display: flex;
      .part {
        width: calc(50% - 15px);
      }
      .part + .part {
        margin-top: 0;
        margin-left: 30px;
      }
    }
  }
}

.clickable {
  cursor: pointer;
}

.btn-billing {
  // border-radius: 10px !important;
  background-color: #dfe3f9 !important;
  color: black !important;
  display: block !important;
  font-size: 13px !important;
  // font-weight: 500 !important;
  &:hover {
    background: #bec1d4 !important;
  }
  &:disabled {
    background: #e4e1e1 !important;
  }
}
.checkbox{
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border: 1px solid #B8B8B8;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  &.active{
    background: #2e49d1;
    border: 0;
  }
  &.disabled{
    background: #BDBDBD;
    border: 0;
  }
  img{
    min-width: max-content;
    min-height: max-content;
  }
}