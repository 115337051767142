.authLayout {
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  .imgPart {
    flex-shrink: 0;
    width: 50%;
    background: linear-gradient(180deg, #20409E 0%, #2E49D1 100%);
    position: relative;
    display: none;
    align-items: center;
    justify-content: center;
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      background-image: url("#{$imgPathNew}/auth-bg.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    img {
      display: block;
      max-width: 100%;
    }
    .imgPartContent {
      color: #fff;
      position: relative;
      z-index: 2;
      .textHolder {
        padding: 30px;
        max-width: 405px;
        margin: auto;
      }
      h2 {
        margin-bottom: 30px;
      }
      p {
        color: #6D7ED3;
      }
      .imgHolder {
        padding: 30px;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
        img {
          display: block;
          margin: auto;
          max-width: 100%;
        }
      }
    }
  }
  .formPart {
    flex-shrink: 0;
    width: 100%;
    //margin-left: 132px;
    display: flex;
    align-items: center;
    justify-content: center;
    .formPartContent {
      padding: 30px;
      max-width: 405px;
    }
  }
  .authCheckHolder {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .linkHolder {
    font-family: $fontFamilySecondary;
  }
  .logoHolder {
    margin-bottom: 30px;
    img {
      display: block;
      width: 135px;
    }
  }
}


.authFormNav {
  list-style: none;
  display: flex;
  align-items: center;
  .authFormNavItem {
    a {
      color: $gray400;
      text-decoration: none;
      border-bottom: 1px solid transparent;
      padding-bottom: 2px;
      display: block;
      font-family: $fontFamilySecondary;
      font-size: $fontSizeH2;
      &.active {
        color: $primaryColor;
        border-color: $primaryColor;
      }
    }
  }
  .authFormNavItem + .authFormNavItem {
    margin-left: 30px;
  }
}

@media (min-width: $screen-sm) {

}

@media (min-width: $screen-md) {

}

@media (min-width: $screen-lg) {
  .authLayout {
    .imgPart {
      width: 50%;
      display: flex;
    }
    .formPart {
      width: 50%;
    }
  }
}

@media (min-width: $screen-xl) {

}