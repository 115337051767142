.searchHolder {
  .resultHolder {
    margin-top: 15px;
  }
  .MuiOutlinedInput-input {
    background-color: #fff;
    border-radius: 0 4px 4px 0;
  }
  .MuiOutlinedInput-root {
    background-color: #fff;
  }
}
.columnsButtonWrapper{
   font-size: 12px;
   display: flex;
   align-items: center;
   gap: 5px;
  }
.LeadsTabs{
  margin-bottom: 15px;
}
.LeadsTabsTab{
  padding: 6px 12px 6px 0 !important;
}


.chipLike {
  display: inline-flex;
  color: #868687;
  background-color: #F0F1F2;
  border: 1px solid #868687;
  font-size: 0.8125rem;
  padding: 2px 12px;
  line-height: 16px;
  border-radius: 4px;
  vertical-align: middle;
  align-items: center;
  width: 123px;
  height: 24px;
  font-weight: 300;
  justify-content: center;
  &.success {
    background-color: $successColor;
    color: $textSuccessColor;
    border-color: $textSuccessColor;
  }
  &.active {
    background-color: $activeColor;
    color: $textActiveColor;
    border-color: $textActiveColor;
  }
  &.warning{
    background-color: $warningColor;
    border-color: #868687;
  }
  &.failed {
    background-color: $failedColor;
    color: $textFailedColor;
    border-color: $textFailedColor;
  }
  &.primary {
    background-color: $primaryColor;
    border-color: #868687;
  }
  &.blackList {
    background-color: $blacklistColor;
    color: $textBlacklistColor;
    border-color: $textBlacklistColor;
  }
  &.flagged {
    background-color: $flaggedColor;
    color: $textFlaggedColor;
    border-color: $textFlaggedColor;
  }
  &.scheduled {
    background-color: $sheduledColor;
    color: $textSheduledColor;
    border-color: $textSheduledColor;
  }
  &.pending, &.draft {
    background: $pendingColor;
    color: $textPendingColor;
    border-color: $textPendingColor;
  }
  &.inactive {
    background: $inactiveColor;
    color: $textInactiveColor;
    border-color: $textInactiveColor;
  }
  &.paused {
    background-color: $pausedColor;
    color: $textPausedColor;
    border-color: $textPausedColor;
  }
  &.completed {
    background-color: $completedColor;
    color: $textCompletedColor;
    border-color: $textCompletedColor;
  }

}
.fileRequirements{
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
  gap: 10px;
  align-items: center;
}
.fileDropHolder {
  border: 2px dashed #BCC0C5;
  padding: 30px;
  border-radius: 4px;
  cursor: pointer;
  &:hover{
    background: #ebeffd;
    transition: all 300ms;
  }
  .icoHolder {
    img {
      display: block;
      margin: auto;
    }
  }
}

.fontSize14 {
  font-size: 14px;
}

.MuiTextField-root {
  &.freqAdornment {
    .MuiOutlinedInput-adornedEnd {
      padding-right: 7px;
    }
    .MuiInputAdornment-root {
      .MuiInput-root {
        &:before,
        &:after {
          display: none;
        }
      }
      .MuiSelect-select:focus {
        background-color: transparent;
      }
    }
  }
}

.fileProgressHolder {
  
  .fileProgress {
    list-style: none;
  }
  .fileProgressItem {
    display: flex;
    align-items: center;
    .icoHolder {
      flex-shrink: 0;
      margin-right: 15px;
      img {
        display: block;
      }
    }
  }
  .progress {
    width: calc(100% - 45px);
  }
  .progressStatus {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}


.editTextOnClick {
  display: flex;
  align-items: center;
  .editBtn {
    font-size: 15px;
    padding: 10px;
  }
  .saveBtn {
    font-size: 15px;
    padding: 10px;
  }
  .captionPart {
    width: 100%;
    .caption {
      padding: 6px 0 7px;
      font-size: 14px;
      line-height: 22px;
      display: inline-block;
      cursor: pointer;
    }
  }
  .btnPart {
    width: 38px;
    margin-left: 10px;
  }
}

@media (min-width: $screen-lg) {
  .searchHolder {
    display: flex;
    align-items: center;
    &.hasFilter {
      justify-content: space-between;
    }
    .resultHolder {
      margin-left: 15px;
      margin-top: 0;
    }
  }
}
