a {
  text-decoration: none;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: 500;
}
.underline {
  
}
.text-semi-bold {
  font-weight: 600;
}

.text-light {
  font-weight: 300;
}

.text-right {
  text-align: center!important;
}
.text-Right {
  text-align: right!important;
}
.width100{
  width: 240px;
}
.w-fit-content{
  width: fit-content;
  text-align: center !important;
}
.text-grey{
  color: grey;
}
.textAttributes {
  text-align: right!important;
}

.text-size-normal {
  font-size: $fontSize;
}

.text-mute {
  font-size: $fontSizeMute;
}

.text-lead {
  font-size: $fontSizeLead;
  font-family: $fontFamilySecondary;
}
.text-fontSecondary{
  font-family: $fontFamilySecondary !important;
}
.keppLoggedContainer{
  display: flex;
  gap: 10px;
  margin: 15px 0;
}
.fs14{
  font-size: 14px;
}
.fs12{
  font-size: 12px;
}
.textPlaceholderColor {
  color: #BCC0C5;
}
.linkPrimary {
  color: $primaryColor!important;
  &:hover {
    color: darken($primaryColor, 5%)!important;
    text-decoration: underline;
  }
}

.textPrimary {
  color: $primaryColor!important;
}

.linkColorNormal {
  color: $gray600!important;
  &:hover {
    color: darken($gray600, 5%)!important;
    text-decoration: underline;
  }
}

.cursor-pointer {
  cursor: pointer;
}

h1 {
  font-family: $fontFamilySecondary;
  font-size: $fontSizeH1;
  font-weight: 500;
}

h2 {
  font-family: $fontFamilySecondary;
  font-size: $fontSizeH2Mobile;
  font-weight: 500;
}

h3 {
  font-family: $fontFamilySecondary;
  font-size: $fontSizeH3;
  font-weight: 500;
}

@media (min-width: $screen-lg) {
  h2 {
    font-family: $fontFamilySecondary;
    font-size: $fontSizeH2;
    font-weight: 500;
  }
}

.SearchField{
  font-size: 14px !important;
}