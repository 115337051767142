//vars
//$heightHeaderDesktop: 105px;
//$widthMainMenu: 200px;
//$widthAsideMenu: 220px;
//$widthBreadCrumbs: $widthMainMenu + $widthAsideMenu;

.mainHeader {
  height: $heightHeaderMobile;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  border-bottom: 1px solid $borderColor;
  background-color: $gray100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  .logoHolder {
    display: none;
  }
  .breadCrumbsHolder {
    display: none;
  }
  .userMenuHolder {
    flex-shrink: 0;
    display: flex;
    align-items: center;
  }
}
.flag {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  img {
    display: block;
    width: 100%;
  }
}

.MuiPopover-paper {
  .flag {
    margin-right: 5px;
  }
}
.mainMenu {
  display: none;
}

.asideMenu {
  display: none;
}

.mobileSubMenu {
  display: flex;
  padding: 15px 15px 0;
  justify-content: space-between;
  align-items: center;
}

.mainLayoutContent {
  min-height: 100vh;
  width: 100%;
  padding-top: $heightHeaderMobile;
  background: #FFFFFF;
  transition: .2s;
}

.mainMenuDrawer {
  z-index: 98 !important;
  .MuiBackdrop-root {
    top: $heightHeaderMobile;
    height: calc(100% - #{$heightHeaderMobile});
    z-index: 98;
  }
  .MuiDrawer-paper {
    top: $heightHeaderMobile;
    height: calc(100% - #{$heightHeaderMobile});
    z-index: 98;
  }
}

.mainContainer {
  margin: auto;
  padding: 0 15px;
}
.height85{
  height: 85%;
}
.menuList {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  &.mobile {
    min-width: 200px;
  }
  .menuListItem {
    display: flex;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    position: relative;
    border-radius: 6px;
    &.marginTopAuto{
      margin-top: auto !important;
    }
    a {
      color: $gray500;
    }
    &:hover {
      background: rgba(216,216,216,.17);
      .ico {
        opacity: .5;
      }
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 3px;
      height: 100%;
      background-color: transparent;
      border-radius: 0 10px 10px 0;
      top: 0;
      left: -18px;
    }
    &.active {
      background: rgba(216,216,216,.37);
      &:before {
        background-color: #fff;
      }
      .ico {
        opacity: 1;
      }
    }
    .ico {
      display: block;
      width: 24px;
      height: 24px;
      object-fit: contain;
      object-position: center;
      opacity: .3;
    }
  }
  .menuListItem + .menuListItem {
    margin-top: 5px;
  }
}

.pageHolder {
  padding: 15px 0;
}
.CampaignNameWidth{
  width: 200px;
  max-width: 200px;
  min-width: 200px;
  overflow: hidden;
}

.pageHeadingBlock {
  //background-color: $gray100;
  //border: 1px solid $borderColor;
  //border-radius: 10px;
  //padding: 15px;
  position: relative;
  .moreIcon {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  .pageHeadingBlockWrapper {
    display: flex;
    flex-wrap: wrap;
     .staticCard {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-right: 10px;
      font-size: 14px;
      color: #636465;
     }
  }
  .part {
    padding: 20px;
    display: flex;
    width: 260px;
    box-shadow: 0px 2px 10px rgba(236, 226, 226, 0.5);
    border-radius: 12px;
    margin: 7.5px;
    background: #fff;
    .icoHolder {
      width: 38px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(46,73,209,.17);
      border-radius: 6px;
      position: relative;
      z-index: 2;
      left: 6px;
      bottom: 6px;
      margin-right: 0;
      margin-bottom: 15px;
      flex-shrink: 0;
      &:before {
        content: "";
        display: block;
        width: 38px;
        height: 38px;
        border-radius: 6px;
        position: absolute;
        background-color: $primaryColor;
        left: -6px;
        bottom: -6px;
        z-index: 1;
      }
      img {
        display: block;
        width: 20px;
        height: 20px;
        object-fit: contain;
        position: relative;
        left: -6px;
        bottom: -6px;
        z-index: 3;
      }
    }
    &.main {
      //flex-grow: 3;
    }
  }
  .hasSwitch {
    display: flex;
    align-items: center;
  }
}
.searchWrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pageTableBlock {
  padding: 0;
  .searchHolder {
    margin-bottom: 15px;
  }
}
.profileItemContent{
  display: flex;
  align-items: center;
  &.active svg{
    fill:"#5975FF",
  }
  &.profileItemContent_title_description{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // margin-left: 10px;
    &.title{
      display: flex;
      align-items: flex-start;
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      color: #1F2123;
      text-transform: capitalize;
    }
    &.description{
      display: flex;
      align-items: flex-start;
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      color: #1F2123;
      text-transform: capitalize;
    }
  }
}

.secondMenuList {
  background: linear-gradient(180deg, #20409E 0%, #2E49D1 100%);
  width: 230px;
  position: fixed;
  z-index: 97;
  left: $widthMainMenu;
  height: 100%;
  padding: 30px 15px;
  .secondMenuListItem {
    a {
      padding: 8px;
      color: #fff;
      text-decoration: none;
      display: block;
      font-size: 14px;
      border-radius: 4px;
      background: #304ead;
      cursor: pointer;
      transition: .2s;
      &:hover {
        background: #2E49D1;
      }
    }
  }
  .secondMenuListItem + .secondMenuListItem {
    margin-top: 15px;
  }
}

//mobile
@media (min-width: $screen-sm) {
}

//mobile
@media (min-width: $screen-md) {
}

//tablet
@media (min-width: $screen-lg) {
  .pageHeadingBlock {
    //padding: 54px 20px;
  }
  .mobileMainMenuHolder {
    display: none;
  }
  .mobileSubMenu {
    display: none;
  }
  .mainHeader {
    background-color: $primaryColorLightSecondary;
    justify-content: unset;
    align-items: unset;
    height: $heightHeaderDesktop;
    padding: 0;
    z-index: 99;
    left: $widthMainMenu;
    width: calc(100% - #{$widthMainMenu});
    border-bottom: none;
    transition: .2s;
    .headerActionsHolder {
      display: flex;
      align-items: center;
    }
    .headerBalance {
      display: flex;
      align-items: center;
      //color: #fff;
      margin-right: 15px;
      .qntHolder {
        font-size: 12px;
        font-family: $fontFamilySecondary;
      }
      & > * + * {
        margin-left: 15px;
      }
    }
    .headerSmsStat {
      margin-right: 15px;
      font-size: 12px;
      font-family: $fontFamilySecondary;
      //color: #fff;
    }
    .menuList {
      flex-direction: row;
      .menuListItem {
        padding: 6px;
        background-color: $primaryColor;
        border-radius: 4px;
        &:before {
          display: none;
        }
        .ico {
          width: 18px;
          height: 18px;
          opacity: 1;
        }&:hover {
          background: rgb(32, 51, 146);
        }
        &.disabled {
          .ico {
            opacity: .3;
          }
          &:hover {
            background: transparent;
          }
        }
      }
      .menuListItem + .menuListItem {
        margin-top: 0;
        margin-left: 10px;
      }
    }
    .breadCrumbsHolder {
      //width: calc(100% - #{$widthBreadCrumbs});
      padding: 15px;
      display: flex;
      align-items: center;
      .breadcrumbs {
        list-style: none;
        display: flex;
        align-items: center;
      }
      .breadcrumbsItem {
        font-family: $fontFamilySecondary;
        font-size: 18px;
        font-weight: 500;
        color: rgba(0,0,0,.45);
        cursor: pointer;
        &.active {
          color: #1F2123;
          cursor: auto;
        }
      }
    }
    .userMenuHolder {
      flex-shrink: 0;
      min-width: $widthAsideMenu;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 50px;
      margin-left: auto;
    }
  }
  .mainMenu {
    width: $widthMainMenu;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 98;
    background-color: $primaryColor;
    display: block;
    .logoHolder {
      padding: 20px 15px;
      img {
        display: block;
        width: 60px;
      }
    }
  }
  .mainLayoutContent {
    min-height: 100vh;
    width: 100%;
    padding-top: $heightHeaderDesktop;
    padding-left: $widthMainMenu;
  }
  .pageTableBlock {
    //padding: 0 21px;
    .searchHolder {
      margin-bottom: 15px;
    }
  }
}
.add-button-img {
  width: 15px;
  margin-right: 10px;
}

.free-sms-block {
  margin-right: 50px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  line-height: 1.75;
  border-radius: 10px;
  text-transform: none;
  font-size: 14px;
}

//desktop
@media (min-width: $screen-xl) {
}

.mobile-menu-enter {
  opacity: 0;
}
.mobile-menu-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.mobile-menu-exit {
  opacity: 1;
}
.mobile-menu-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.sub-menu-enter {
  //opacity: 0;
  transform: translateX(-320px);
}
.sub-menu-enter-active {
  //opacity: 1;
  transform: translateX(0);
  // transition: transform 200ms;
  transition: all 300ms;
}
.sub-menu-exit {
  transform: translateX(0);
}
.sub-menu-exit-active {
  transform: translateX(-320px);
  // transition: transform 200ms;
  transition: all 300ms;
}
.pageHeadingSegmentsWrapper {
  display: flex;
  flex-wrap: wrap;
   .staticCard {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-right: 10px;
    font-size: 14px;
    color: #636465;
   }
}