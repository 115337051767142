@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Montserrat:wght@300;400;700&display=swap');
@import "variables";
@import "reset";
@import "colors";
@import "borders";
@import "wrappers";
@import "typography";
@import "spacings";
@import "buttons";
@import "icons";
@import "tables";
@import "forms";
@import "lists";
@import "modal";
@import "body";
@import "layout";
@import "tabs";
@import "auth";
@import "single-lead";
@import "segments";
@import "campaigns";
@import "profile";
@import "chat-btn";
@import "statistics";

.inputDatePicker {
  width: 100%;
  min-height: 1.1876em;
  border-radius: 9px;
  padding: 11px 12px 10px 12px;
  border: 1px solid #e5e5e5;
  // background-color: #fafafa;
  font-size: 16px;
  color: rgb(31, 33, 35);
  font-weight: 300;
  font-family: $fontFamilySecondary;
  &:focus-visible {
    outline: none;
  }
}
.react-datepicker-popper {
  z-index: 2 !important;
}
.react-datepicker__header{
  background-color: #2E49D1 !important;
}
.react-datepicker-time__header{
  color: white !important;
}
.react-datepicker__current-month{
  color: white !important;
}
.react-datepicker__day--selected{
  background-color: #2E49D1 !important;
}
.react-datepicker__time-list-item--selected{
  background-color: #2E49D1 !important;
}
.react-datepicker__navigation-icon::before{
  border-color: white !important;
}
.react-datepicker__day-name{
  color: white !important;
}
.dateLabel{
  background: white;
  padding: 0 5px 0 5px !important;
}
.welcomeHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-grow: 1;
  height: calc(100vh - #{$heightHeaderDesktop});
}

.importPopover {
  .MuiPaper-root {
    width: 300px;
    border-radius: 10px;
  }
}

.importPopoverContent {
  .ipTitleWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid $borderColor;
    .title {
      font-size: 12px !important;
    }
  }
  .fileProgressHolder {
    padding: 15px;
    .checkboxWrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      height: 40px;
    }
    .progress {
      width: calc(100% - 75px);
    }
  }
  .fileProgressItem {
    margin-bottom: 30px;
    align-items: center;
    &.lookUpItem {
      margin-bottom: 0;
    }
  }
  .lookUpItem + .lookUpItem {
    margin-top: 15px;
  }
  .statusIcoHolder {
    margin-left: 15px;
    flex-shrink: 0;
    .MuiSvgIcon-root {
      width: 15px;
      display: block;
    }
  }
}