$fontFamily: 'Montserrat', sans-serif;
//$fontFamily: 'Montserrat', sans-serif;
$fontFamilySecondary: 'Montserrat', sans-serif;

$heightHeaderDesktop: 50px;
$heightHeaderMobile: 54px;
$widthMainMenu: 90px;
$widthAsideMenu: 220px;
$widthBreadCrumbs: $widthMainMenu + $widthAsideMenu;


/* Responsive screen width */

$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1400px;

/* Color vars */

$primaryColor: #2E49D1;
$primaryColorLight: #5975FF;
$primaryColorLightSecondary: #dfe3f9;
$secondaryColor: #2E3A59;

$black: #000;
$gray100: #FBFBFB;
$gray200: #F5F5F5;
$gray300: #E4E6E7;
$gray400: #AFB1B3;
$gray500: #46484A;
$gray600: #1F2123;
$white: #fff;

$warningColor: #E05263;
$activeColor: #F1FFCA;
$textActiveColor: #1D1D1D;
$successColor: #F1FFCA;
$textSuccessColor: #1D1D1D;
$blacklistColor: #F2F4F7;
$textBlacklistColor: #344054;
$failedColor: #FFF1F3;
$textFailedColor: #C01048;
$flaggedColor: #FFE3CA;
$textFlaggedColor: #1D1D1D;
$sheduledColor: #FFFAEB;
$textSheduledColor: #B54708;
$pendingColor: #E4E4E4;
$textPendingColor: #1D1D1D;
$pausedColor: #EECAFF;
$textPausedColor: #1D1D1D;
$completedColor: #CAD2FF;
$textCompletedColor: #1D1D1D;
$draftColor: #E4E4E4;
$textDraftColor: #1D1D1D;
$inactiveColor: #E4E4E4;
$textInactiveColor: #1D1D1D;

$borderColor: #e5e5e5;

$fontSize: 16px;
$fontSizeMobile: 14px;
$fontSizeMute: 14px;
$fontSizeLead: 18px;
$fontSizeH1: 34px;
$fontSizeH2: 24px;
$fontSizeH2Mobile: 16px;
$fontSizeH3: 20px;

$imgPath: './../../../assets/img';
$imgPathNew: './../../../assets/img-new';
