.profilePageHolder {
  padding: 15px 0 0;
  .navPart {
    width: 100%;
    margin-bottom: 30px;
  }
  .contentPart {
    width: 100%;
  }
  .mainSubscriptionPlanHolder {

  }
  .mainSubscriptionPlan {
    border-radius: 12px;
    background-color: #2E3A59;
    padding: 15px;
    color: #fff;
    margin-bottom: 45px;
    .userInfoWrapper {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
    }
    .userInfoHolder {
      width: 28%;
    }
    .nameHolder {
      .subName {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 5px;
      }
      .name {
        font-family: $fontFamilySecondary;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 5px;
      }
      .expDate {
        font-size: 14px;
        line-height: 17px;
        color: rgba(250, 250, 250, 0.3);
        margin-bottom: 15px;
      }
    }
    .userInfo {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-bottom: 20px;
    }
    .userName {
      font-size: 28px;
      margin: 0 0 5px;
      font-weight: bold;
    }
    .avaHolder {
      width: 100px;
      height: 100px;
      flex-shrink: 0;
      margin-right: 10px;
      img {
        display: block;
        width: 100%;
        border-radius: 50%;
      }
    }
    .infoLevel{
      margin-top: 10px;
      width: 80%;
      min-width: fit-content;
      border: 1px solid #C9CEE7;
      padding: 10px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      img{
        margin-right: 10px;
      }
      .title{
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #FAFAFA;
      }
    }

    .border{
      border-right: 1px solid rgba(250, 250, 250, 0.3);
      border-left: 1px solid rgba(250, 250, 250, 0.3);
    }
    .padding30{
      padding: 0 30px 0 30px;
    }
    .planInfoHolder {
      padding-left: 15px;
      width: 27%;
      min-width: 315px;
      .subName {
        font-size: 14px;
      }
      .name {
        font-weight: bold;
        font-size: 18px;
      }
      .planInfoTitleHolder {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
      }
    }
  }
  .profileItemContent{
    display: flex;
    align-items: center;
    width: max-content;
    &.active svg{
      fill:"#5975FF";
    }
    &.profileItemContent_title_description{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 10px;
      &.title{
        display: flex;
        align-items: flex-start;
        width: 100%;
        font-weight: 500;
        font-size: 14px;
        color: #1F2123;
        text-transform: capitalize;
      }
      &.description{
        display: flex;
        align-items: flex-start;
        width: 100%;
        font-weight: 400;
        font-size: 14px;
        color: #1F2123;
        text-transform: capitalize;
      }
    }
  }
  .spInfoList {
    list-style: none;
    .spInfoListItem {
      width: 80%;
      min-width: fit-content;
      border: 1px solid #C9CEE7;
      padding: 10px;
      border-radius: 12px;
      .spInfoListItemContent {
        display: flex;
        align-items: center;
      }
    }
    .spInfoListItem + .spInfoListItem {
      margin-top: 15px;
    }
    .icoHolder {
      margin-right: 10px;
      img {
        width: 32px;
        display: block;
      }
    }
    .countQnt {
      margin-left: auto;
      margin-bottom: auto;
      font-size: 14px;
      line-height: 17px;
      color: #E4E6E7;
    }
    .progressTitle {
      font-family: $fontFamilySecondary;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #FAFAFA;
    }
    .progressBar {
      height: 7px;
      background: rgba(46, 73, 209, 0.406605);
      border-radius: 5.5px;
      position: relative;
      width: 240px;
      .bar {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background: #5975FF;
        border-radius: 5.5px;
      }
    }
  }
  .profileNavHolder {
  }
  .transactionDate{
  }
  .transactionAmount{
    text-align: right;
    width: fit-content;
    padding-right: 100px;
  }
  .transactionAmountPositive{
    color: #61BD4F;
  }
  .transactionAmountNegative{
    color: #C01048;
  }
  .profileNav {
    list-style: none;
    .pnItem {
      border-bottom: 1px solid $borderColor;
      padding: 15px 10px;
      &:first-child {
        padding-top: 0;
      }
    }
    .pnItemContent {
      display: block;
      position: relative;
      padding-left: 42px;
      padding-right: 34px;
      &:before, &:after {
        content: "";
        width: 32px;
        height: 32px;
        display: block;
        position: absolute;
        top: 0;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      &:before {
        left: 0;
        background-image: url("#{$imgPathNew}/key-info.png");
        top: 5px;
      }
      &:after {
        right: 0;
        width: 24px;
        height: 24px;
        top: 50%;
        margin-top: -12px;
        background-image: url("#{$imgPathNew}/chevron-right.png");
      }
    }
    .title {
      display: block;
      font-size: 18px;
      color: #1F2123;
      font-weight: bold;
    }
    .description {
      display: block;
      color: #1F2123;
      font-size: 14px;
    }
  }
  .contentPartTitle {
    padding-bottom: 15px;
    // margin-bottom: 30px;
    font-size: 30px;
    font-weight: bold;
  }
  .planName{
    display: inline-block;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .text {
    font-size: 14px;
  }
  .cpSubTitle {
    margin-bottom: 15px;
    font-size: 18px;
  }
  .subscriptionPlanTable {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    .titleCell {
      font-family: $fontFamilySecondary;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #2E3A59;
      padding: 0 30px 20px 30px;
    }
    th {
      background-color: #F0F0F7;
      color: #2E3A59;
      font-weight: 500;
    }
    td {
      font-size: 14px;
      line-height: 17px;
      color: #1F2123;
    }
    th, td {
      padding: 4px;
      vertical-align: middle;
    }
    tr {
      th, td {
        &:first-child {
          padding-left: 30px;
        }
        &:last-child {
          padding-right: 30px;
        }
      }
    }
    tbody + tbody {
      .titleCell {
        padding-top: 30px;
      }
    }
  }
  .paymentMethodList {
    display: flex;
    margin: -7.5px;
    flex-wrap: wrap;
    list-style: none;
    .pmlItem {
      padding: 7.5px;
      min-height: 140px;
      &.default {
        .pmlItemContent {
          display: flex;
          align-items: center;
          justify-content: center;
          border-color: transparent;
          .imgHolder {
            border-radius: 50%;
            background: rgba(5,118,237,0.05);
            padding: 15px;
            cursor: pointer;
          }
          img {
            display: block;
            width: 24px;
          }
        }
      }
    }
    .pmlItemContent {
      border: 2px solid $primaryColor;
      border-radius: 12px;
      background: rgba(5,118,237,0.05);
      width: 280px;
      height: 100%;
      padding: 15px;
    }
    .title {
      font-size: 18px;
      font-weight: bold;
      color: $primaryColor;
      margin-bottom: 15px;
    }
    .cardInfo {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }
    .imgHolder {
      img {
        width: 60px;
      }
    }
    .numberHolder {
      margin-left: 10px;
    }
  }
  .billingHistoryTableHolder {
    overflow-y: auto;
    .billingHistoryTable {
      width: 100%;
      font-size: 14px;
      text-align: left;
      th, td {

      }
      th {
        padding: 0 24px 24px;
      }
      td {
        padding: 6px 24px;
      }
      a {
        color: $primaryColor;
      }
      .date, .price {
        font-weight: bold;
      }
    }
  }
  .avaFormHolder {
    display: flex;
    align-items: center;
    .imgHolder {
      margin-right: 15px;
      img {
        width: 90px;
        height: 90px;
        display: block;
        border-radius: 50%;
      }
    }
  }
  .passRulesBlock {
    width: calc(50% - 30px);
    background: $primaryColor;
    color: #fff;
    padding: 30px 15px;
    border-radius: 12px;
    display: none;
    &.mobile {
      width: 100%;
      display: block;
    }
    .title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 15px;
    }
    p {
      margin-bottom: 15px;
    }
    ul {
      padding-left: 20px;
    }
  }
  .profileFormHolder {
    display: flex;
    justify-content: space-between;
  }
}
.subscriptionPlan{
  margin-bottom: 40px;
}
@media (min-width: $screen-sm) {
  .profilePageHolder {
    .subscriptionPlanList {
      .splItem {
        width: 50%;
      }
    }
  }
}

@media (min-width: $screen-md) {
  .profilePageHolder {
    .passRulesBlock {
      display: block;
      &.mobile {
        display: none;
      }
    }
    .formHolder {
      width: 50%;
    }
    .profileNavHolder {
      margin-right: 0;
      margin-bottom: 30px;
    }
    .contentPart {
      width: 100%;
      border-left: none;
      padding-left: 0;
    }
    .subscriptionPlanList {
      .splItem {
        width: 50%;
      }
    }
  }
}

@media (min-width: $screen-lg) {
  .profilePageHolder {
    .profileNavHolder {
      margin-right: 0;
      margin-bottom: 30px;
    }
    .passRulesBlock {
      display: none;
      &.mobile {
        display: block;
      }
    }
    .formHolder {
      width: 100%;
    }
    .subscriptionPlanList {
      .splItem {
        width: 50%;
      }
    }
  }
}

@media (min-width: $screen-xl) {
  .profilePageHolder {
    .passRulesBlock {
      display: block;
      &.mobile {
        display: none;
      }
    }
    .formHolder {
      width: 50%;
    }
    .profileNavHolder {
      margin-right: 0;
      margin-bottom: 30px;
    }
    .subscriptionPlanList {
      .splItem {
        width: 50%;
      }
    }
  }
}

@media (min-width: $screen-xxl) {
  .profilePageHolder {
    .profileNavHolder {
      margin-right: -15px;
      margin-bottom: 0;
    }
    .subscriptionPlanList {
      .splItem {
        width: 50%;
      }
    }
  }
}
.subscriptionPlanListHolder{
  // border-radius: 8px;
  // overflow: hidden;
  // border: 0.5px solid #E5E5E5;
  // padding: 5px;
}