.modal {
  .modalOverflow{
    overflow: hidden;
    overflow-y: auto;
  }
  .modalOverflow::-webkit-scrollbar {
    width: 0em;
  }
  .modalBody {
    max-width: 496px;
    width: 100%;
    padding: 30px;
  }
  h2 {
    font-size: 20px;
    text-align: center;
    font-weight: 500;
  }
  &.hasWizard {
    .MuiPaper-rounded {
      border-radius: 0 0 10px 10px;
    }
  }
  .indicatorHolder {
    position: absolute;
    border-radius: 10px 10px 0 0;
    display: flex;
    width: 100%;
    top: -8px;
    overflow: hidden;
    .indicatorItem {
      height: 8px;
      flex-grow: 1;
      &.active {
        background-color: $primaryColor;
      }
    }
  }
  .wizardStepsHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    .MuiTab-root{
      font-family: $fontFamilySecondary;
      font-weight: 400;
      text-transform: capitalize;
    }
    .stepItem {
      font-family: $fontFamilySecondary;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #AFB1B3;
      border-bottom: 1px solid transparent;
      padding-bottom: 5px;
      &.active {
        color: $primaryColor;
        border-bottom: 1px solid $primaryColor;
      }
    }
    .stepItem + .stepItem {
      margin-left: 30px;
    }
  }
}
.messages{
  .MuiPaper-root{
    max-width: 450px !important;
    max-height: calc(100% - 344px);
    overflow: hidden !important;
  }
}
.SingleSmsModalAutocomplete{
    >div{
      padding: 0 !important;
    }
  }
.autocompleteList{
  display: inline-block;
  margin-left: 10px;
  color: grey;
}
.centred {
  text-align: center;
}

.addFundsRadioHolder {
  .afrList {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .afrListItem + .afrListItem {
    margin-left: 12px;
  }
  .afrListItem {

    input[type="radio"] {
      display: none;
    }
    label {
      width: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: 1px solid #E5E5E5;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 13px 15px;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      transition: .2s;
    }
    input[type="radio"]:checked + label {
      background-color: #5975FF;
      border-color: #5975FF;
      color: #fff;
    }
  }
}

.paymentSystemHolder {
  .psList {
    list-style: none;
    display: flex;
    justify-content: center;
  }
  .psListItem {
    .psItemContent {
      display: flex;
      width: 70px;
      padding: 13px 15px;
      border: 1px solid #E5E5E5;
      box-sizing: border-box;
      border-radius: 4px;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    img {
      display: block;
      max-width: 100%;
    }
  }
  .psListItem + .psListItem {
    margin-left: 12px;
  }
}
.TextFieldMenuItem{
  font-size: 14px !important;
  min-height: 0 !important;
  &:hover{
    background-color: #ebeffd !important;
  }
}
.dropdownWrapper{
  &.MuiMenu-paper {
    min-width: fit-content;
  }
}
.attributeButtonWrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
}