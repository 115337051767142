.campaignPageHolder {
  padding: 15px 0 0;
  .stepsList {
    list-style: none;
    .stepsListItem {
      position: relative;
      padding-top: 36px;
      padding-left: 56px;
      // &:last-child {
      //   &:before {
      //     height: 36px;
      //   }
      //   .plusBtn {
      //     display: none;
      //   }
      // }
      &:before {
        content: "";
        display: block;
        width: 1px;
        height: 100%;
        left: 21px;
        top: 0;
        background: $borderColor;
        position: absolute;
      }
    }
    .icoHolder {
      position: absolute;
      left: 0;
      width: 41px;
      height: 41px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #E4E6E7;
      border-radius: 50%;
      img {
        display: block;
        width: 21px;
        height: 21px;
      }
    }
    .plusBtn {
      position: absolute;
      left: 9px;
      cursor: pointer;
      bottom: 0;
      display: block;
      border: none;
      outline: none;
      box-shadow: none;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-image: url("#{$imgPathNew}/plus-btn.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .sliContent {
      background: #FBFBFB;
      border: 1px solid #F5F5F5;
      border-radius: 10px;
      overflow: hidden;
    }
    .sliTitleHolder {
      background: #F5F5F5;
      padding: 15px;
      position: relative;
    }
    .sliTitle {
      font-size: 14px;
      font-weight: 600;
      color: #313462;
      font-family: $fontFamilySecondary;
    }
    .sliSubMenu {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    .sliBodyHolder {
      display: flex;
      padding: 15px;
      font-size: 14px;
      font-weight: 300;
      .qnt {
        flex-shrink: 0;
        padding-left: 15px;
        color: #AFB1B3;
      }
    }
  }
  .campSettingsHolder {
    margin-top: 30px;
  }
}

.campaignsPageHolder {
  padding: 15px 0;
}

.tabsWithStatusHolder {
  position: relative;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: 1px solid #E4E6E7;
  .pageTabs.MuiTabs-root {
    border-bottom: none;
  }
  .campaignStatus {
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    padding-left: 11px;
    .campaignStatusText {
      &:before {
        content: '';
        display: block;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -3px;
      }
    }
    &.draft {
      .campaignStatusText {
        &:before {
          background-color: #E0C852;
        }
      }
    }
    &.active {
      .campaignStatusText {
        &:before {
          background-color: #72C07F;
        }
      }
    }
  }
  .campaignInfoHolder {
    display: flex;
    align-items: center;
  }
  .campaignInfoList {
    display: flex;
    list-style: none;
    align-items: center;
    font-size: 14px;
    line-height: 17px;
    color: #1F2123;
    .clItem + .clItem {
      margin-left: 30px;
    }
  }
}

.startCampaignHolder {
  max-width: 400px;
  .startCampaign {
    background: #FFFFFF;
    border: 1px solid #E4E6E7;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .ico {
    display: block;
  }
  .textHolder {
    font-weight: 500;
    font-size: 14px;
    color: #46484A;
  }
}

@media (min-width: $screen-xxl) {
  .campaignPageHolder {
    display: flex;
    justify-content: space-between;
    .campSettingsHolder {
      margin-top: 0;
      width: 40%;
    }
    .campStepsHolder {
      width: 100%;
      max-width: 600px;
    }
  }
}

.percentageProgress{
  flex-shrink: 0;
  font-size: 14px;
  line-height: 12px;
  margin-left: 5px;
  width: 30px;
  text-align: right;
}