.popoverMenu {
  .MuiListItemText-root {
    .MuiTypography-body1 {
      font-size: 14px;
      font-family: $fontFamilySecondary;
      font-weight: 500;
      line-height: 18px;
    }
  }
  .MuiListItem-root {
    min-width: 220px;
  }
  .MuiListItemIcon-root {
    min-width: unset;
  }
  .MuiSvgIcon-root {
    color: #2E3A59;
    font-size: 18px;
    margin-right: 15px;
  }
}

.breadcrumbs {
  list-style: none;
  display: flex;
  align-items: center;
  a {
    color: $gray500;
    text-decoration: none;
    cursor: pointer;
  }
  .breadcrumbsItem {
    color: $gray500;
    text-decoration: none;
    cursor: pointer;
    &.active {
      color: $gray600;
    }
  }
  .breadcrumbsItem + .breadcrumbsItem {
    display: flex;
    align-items: center;
    margin-left: 5px;
    &:before {
      content: "";
      width: 8px;
      height: 8px;
      background-image: url("#{$imgPathNew}/chevron_big_right.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      display: block;
      margin-right: 5px;
    }
  }
}

.divider {
  height: 1px;
  background-color: $borderColor;
  margin-top: 30px;
  margin-bottom: 30px;
}

.fileList {
  list-style: none;
  .fileListItem {
    display: flex;
    align-items: center;
  }
  .icoHolder {
    flex-shrink: 0;
    margin-right: 15px;
    img {
      display: block;
    }
  }
  .captionHolder {
    width: calc(100% - 51px);
  }
}
.importInfoText{
  font-weight: 400;
  color: grey;
  font-size: 14px;
  margin-bottom: 10px;
}

.segmentList {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -5px;
  .segmentListItem {
    color: $primaryColor;
    font-weight: 300;
    font-size: $fontSizeMute;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    background-color: #EEF5FC;
    border: 1px solid #DBE8F7;
    border-radius: 5px;
    margin: 0 5px 5px 0;
    .MuiSvgIcon-root {
      margin-left: 5px;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
    }
    &.addBtn {
      cursor: pointer;
      .MuiSvgIcon-root {
        margin-left: 0;
      }
    }
  }
}

.tagsList {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -5px;
  .tagsListItem {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border-radius: 4px;
    margin: 0 5px 5px 0;
    font-size: $fontSizeMute;
    color: #fff;
    background-color: #FB8B24;
    font-weight: 600;
    text-decoration: none;
    .MuiSvgIcon-root {
      margin-left: 5px;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
    }
    &.addBtn {
      cursor: pointer;
      .MuiSvgIcon-root {
        margin-left: 0;
      }
    }
  }
}
.paginationMenuItem {
  .MuiMenuItem-root{
    color: red !important;
  }
}







